// import moment from 'moment';
import moment from 'moment-timezone';

export function ArrayPagination(items: any[], current_page: number, per_page_items: number) {
    let page = current_page || 1,
        per_page = per_page_items || 10,
        offset = (page - 1) * per_page,
        paginatedItems = items.slice(offset).slice(0, per_page_items),
        total_pages = Math.ceil(items.length / per_page);

    return {
        page: page,
        perPage: per_page,
        total: items.length,
        totalPage: total_pages,
        data: paginatedItems,
    };
}
export function generateRandom(min = 0, max = 100) {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    return rand;
}

export function ConvertToGMT0Time(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z` as unknown as Date;
}

export function ConvertToGMT0(date?: Date) {
    if (!date) return undefined;
    const currentGMT = new Date().getTimezoneOffset() / -60;
    return moment(date).utc().add(currentGMT, 'hours').toDate();
}

export const FormatVRNString = (vrn: string) => {
    return vrn.replace(/[^A-Z0-9]/gi, '');
};

export const SliceLongString = (string: string, length: number) => {
    return string.length > length ? string.slice(0, length).concat('...') : string;
};

export const CompareDateWithUTC = (date1: Date, dateLocale: Date) => {
    return moment(date1).format('DD/MM/YYYY') === moment(ConvertToGMT0Time(dateLocale)).format('DD/MM/YYYY');
};
