import { AllocatedPermitType, DurationType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { DurationOptions } from '../Tab/AllocatedTypeItem';

export const getLabelAllocatedTypeName = (allocatedType: AllocatedPermitType) => {
    if (allocatedType === AllocatedPermitType.Once) return 'One day';
    if (allocatedType === AllocatedPermitType.Dynamic) return 'Recurring';
    if (allocatedType === AllocatedPermitType.Indefinite) return 'Indefinite';
    if (allocatedType === AllocatedPermitType.Temporary) return 'Temporary';

    return '';
};

export const getDurationType = (durationType: DurationOptions) => {
    if ([DurationOptions.OneWeek, DurationOptions.TwoWeeks, DurationOptions.ThreeWeeks].includes(durationType)) {
        return DurationType.Week;
    }
    if ([DurationOptions.OneMonth, DurationOptions.TwoMonths, DurationOptions.ThreeMonths].includes(durationType)) {
        return DurationType.Month;
    }
    return DurationType.Bespoke;
};

export const getDurationValue = (durationType: DurationOptions, monthValue?: number, dayValue?: number) => {
    if ([DurationOptions.OneWeek, DurationOptions.TwoWeeks, DurationOptions.ThreeWeeks].includes(durationType)) {
        return parseInt(durationType.split('-')[0]);
    }
    if ([DurationOptions.OneMonth, DurationOptions.TwoMonths, DurationOptions.ThreeMonths].includes(durationType)) {
        return parseInt(durationType.split('-')[0]);
    }
    if (DurationOptions.Bespoke === durationType) {
        let day = 0;
        if (dayValue) day += Number(dayValue);
        if (monthValue) day += Number(monthValue) * 31;
        return day;
    }
    return 0;
};

export const getDurationOptions = (durationType: DurationType, duration: number) => {
    if (durationType === DurationType.Week) {
        if (duration === 1) return DurationOptions.OneWeek;
        if (duration === 2) return DurationOptions.TwoWeeks;
        if (duration === 3) return DurationOptions.ThreeWeeks;
    }
    if (durationType === DurationType.Month) {
        if (duration === 1) return DurationOptions.OneMonth;
        if (duration === 2) return DurationOptions.TwoMonths;
        if (duration === 3) return DurationOptions.ThreeMonths;
        return DurationOptions.OneMonth;
    }
    return DurationOptions.Bespoke;
};
