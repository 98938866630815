import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';
import CheckBoxIcon from '../../../assets/images/CheckBoxIcon';
import Checked from '../../../assets/images/Checked';
import CheckedIcon from '../../../assets/images/CheckedIcon';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AdvancedFilterItem, AdvancedName } from '../hooks/usePermitsFilter';

type Props = {
    label: AdvancedName;
    onChecked: (e: any, name: AdvancedName) => void;
    isChecked: boolean;
    isDisabled: boolean;
};

export default function MenuItemCheckbox(props: Props) {
    const { t } = useContext(LanguageContext);
    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked);

    const setChecked = () => {
        props.onChecked(!isChecked, props.label);
        setIsChecked(!isChecked);
    };
    return (
        <MenuItem
            // disableRipple={true}
            sx={{
                padding: '5px 5px',
                borderBottom: `1px solid ${color.grey200}`,
                borderRadius: '5px',
                '&:hover': {
                    background: color.primary,
                },
                '& .MuiTouchRipple-child': {
                    backgroundColor: color.primary,
                },
            }}
            onClick={setChecked}
            disabled={props.isDisabled}
        >
            <Checkbox
                sx={{ padding: '5px', mr: '5px' }}
                size="small"
                icon={<CheckBoxIcon style={{ width: '22px', height: '22px' }} />}
                checkedIcon={<CheckedIcon style={{ width: '22px', height: '22px' }} />}
                checked={isChecked}
                value={props.label}
            />
            {props.label == 'permitType'
                ? t('permits:text.permitCategory')
                : props.label == 'status'
                ? t('action:status')
                : props.label == 'validFrom'
                ? t('permits:text.validFrom')
                : props.label == 'recurringType'
                ? t('permits:text.permitType')
                : t('permits:text.validTo')}
        </MenuItem>
    );
}
