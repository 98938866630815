/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useContext } from 'react';
import { BsFilter } from 'react-icons/bs';
import CalendarIcon from '../../../assets/svg/CalendarIcon';
import { SelectItem } from '../../../components/MySelect';
import IAutoCompleteRF from '../../../components/autoComplete/IAutoComplete2';
import IMultiSelectWithCheckBoxRF from '../../../components/autoComplete/IMultiSelectWithCheckBoxRF';
import color from '../../../config/Colors';
import font from '../../../config/Fonts';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AuthContext } from '../../../hooks/useAuth';
import { LoadingFilter } from '../../../hooks/useFilter';
import { Company } from '../../../models/Company';
import { LocationWithDetail } from '../../../models/Location';
import { Region } from '../../../models/Region';
import Zone from '../../../models/Zone';
import { AdvancedFilterOptions, StatusFilter } from '../../../models/permits/FilterPermits';
import { RecurringType } from '../../../models/permits/PermitRecurring';
import { Shop } from '../../../models/tenant/Shop';
import IconSearch from '../../companyLayout/components/svg/IconSearch';
import { DataPermitsState, FilterStatePermitId, SelectPermitField } from '../hooks/usePermitsFilter';
import ToolbarTitleDatePicker from './ToolbarTitleDatePicker';

type Props = {
    handleClose: (isOpen: boolean) => void;
    setAdvancedFilter: React.Dispatch<React.SetStateAction<AdvancedFilterOptions>>;
    advancedFilter: AdvancedFilterOptions;
    filterState: FilterStatePermitId;
    dataState: DataPermitsState;
    setPartialFilter: (p: Partial<FilterStatePermitId>) => void;
    loading: LoadingFilter;
    selectField: SelectPermitField | undefined;

    handleChangeLocation: (location?: LocationWithDetail) => void;
    handleChangeCompany: (c?: Company) => void;
    handleChangeRegion: (r?: Region) => void;
    handleChangeShop: (shop?: Shop) => void;
    handleChangeZone: (zones?: Zone[]) => void;
    handleResetFilter: () => void;
    handleApplyFilter: () => void;
};
export enum FilterEnum {
    companyId = 'companyId',
    regionId = 'regionId',
    locationId = 'locationId',
    zoneIds = 'zoneIds',
    shopId = 'shopId',
    all = 'all',
}

export default function FilterModalContent({
    handleClose,
    advancedFilter,
    dataState,
    filterState,
    setAdvancedFilter,
    setPartialFilter,
    loading,
    selectField,
    ...props
}: Props) {
    const { isGoldRole, isBronzeRole } = useContext(AuthContext);
    const { t } = useContext(LanguageContext);
    const listStatus: SelectItem[] = [
        {
            value: StatusFilter.All,
            label: t('permits:text.all'),
        },
        {
            value: StatusFilter.Active,
            label: t('action:active'),
        },
        {
            value: StatusFilter.Expired,
            label: t('action:expired'),
        },
        {
            value: StatusFilter.Pending,
            label: t('action:pending'),
        },
        {
            value: StatusFilter.Deleted,
            label: t('action:delete'),
        },
    ];

    const listRecurType: SelectItem[] = [
        {
            value: -1,
            label: t('permits:text.all'),
        },
        {
            value: RecurringType.once,
            label: t('permits:text.oneDayPermit'),
        },
        {
            value: RecurringType.indefinite,
            label: t('permits:text.indefinite'),
        },
        {
            value: RecurringType.temporary,
            label: 'Temporary',
        },
        {
            value: RecurringType.recurring,
            label: 'Recurring',
        },
    ];

    const getValueStatus = (status: StatusFilter) => {
        return listStatus.find((item) => item.value === status);
    };

    const handleChangeStatus = (val?: SelectItem) => {
        setPartialFilter({ status: val!.value, page: 1 });
    };

    const getPermitTypeValue = (val: string) => {
        return dataState.permitTypes.find((item) => item.value === val);
    };

    const handleChangePermitType = (val?: SelectItem) => {
        setPartialFilter({ type: val!.value, page: 1 });
    };

    const getRecurType = (recur: RecurringType | null) => {
        if (recur === null) return listRecurType[0];
        else return listRecurType.find((item) => item.value === recur);
    };

    const handleChangeRecurType = (val?: SelectItem) => {
        setPartialFilter({ type: val!.value, page: 1 });
    };

    const getZones = () => {
        const zones: Zone[] = [];
        filterState.zoneIds?.forEach((z) => {
            const find = dataState.zones.find((zone) => zone.id === z);
            if (find) zones.push(find);
        });
        return zones;
    };

    return (
        <Grid container padding={'56px 24px 40px'} gap={3}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                <Typography variant="h3" fontFamily={font} fontSize={'20px'}>
                    {t('permits:title.setFilters')}
                </Typography>
                <Button variant="errorOutlined" sx={{ padding: '8px 32px' }} onClick={() => props.handleResetFilter()}>
                    {t('permits:button.reset')}
                </Button>
            </Stack>
            <Grid item container xs={12} spacing={1}>
                <Stack flexDirection={'row'} alignItems={'center'} flex={1} px={1}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="h5" component={'span'} mx={0.5} color={color.primary}>
                        {t('permits:text.filterByPermit')}{' '}
                    </Typography>
                </Stack>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    height: 'auto',
                                },
                            }}
                            fullWidth
                            placeholder={t('permits:text.searchByVRN')}
                            onChange={(e) => setPartialFilter({ searchValue: e.target.value })}
                            defaultValue={filterState.searchValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <IconSearch />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IAutoCompleteRF
                            listData={listStatus}
                            itemValue={getValueStatus(filterState.status!)}
                            label={t('action:status')}
                            keyLabel={'label'}
                            keyEqual={'value'}
                            setFilter={handleChangeStatus}
                            isHaveAllOptions={false}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} mt={1}>
                    <IAutoCompleteRF
                        listData={dataState.permitTypes}
                        itemValue={getPermitTypeValue(filterState.type!)}
                        label={t('permits:text.permitType')}
                        keyLabel={'label'}
                        keyEqual={'value'}
                        setFilter={handleChangePermitType}
                        isHaveAllOptions={false}
                    />
                </Grid>
                <Grid item xs={6} mt={1}>
                    <IAutoCompleteRF
                        listData={listRecurType}
                        itemValue={getRecurType(filterState.recurringType!)}
                        label={t('permits:text.recurringType')}
                        keyLabel={'label'}
                        keyEqual={'value'}
                        setFilter={handleChangeRecurType}
                        isHaveAllOptions={false}
                    />
                </Grid>
                <Grid item container spacing={1} mt={0.5}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('permits:text.validFrom')}
                                value={filterState.validFrom ?? null}
                                onChange={(newValue) => {
                                    if (newValue !== null) {
                                        setPartialFilter({ validFrom: newValue, validTo: undefined });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                // open
                                DialogProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                PopperProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                toolbarTitle={
                                    <ToolbarTitleDatePicker
                                        setModalState={() => {
                                            setPartialFilter({ validFrom: new Date(), validTo: undefined });
                                        }}
                                    />
                                }
                                inputFormat="dd/MM/yyyy"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                minDate={filterState.validFrom}
                                label={t('permits:text.validTo')}
                                value={filterState.validTo ?? null}
                                onChange={(newValue) => {
                                    setPartialFilter({ validTo: newValue! });
                                }}
                                DialogProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                PopperProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                toolbarTitle={
                                    <ToolbarTitleDatePicker
                                        setModalState={() => {
                                            setPartialFilter({ validTo: undefined });
                                        }}
                                    />
                                }
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                inputFormat="dd/MM/yyyy"
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Stack flexDirection={'row'} alignItems={'center'} flex={1} px={1} mt={1}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="h5" component={'span'} mx={0.5} color={color.primary}>
                        {t('permits:text.filterByArea')}{' '}
                    </Typography>
                </Stack>
                <Grid item xs={12} mt={0.5}>
                    <IAutoCompleteRF
                        listData={dataState.locations}
                        itemValue={dataState.locations.find((l) => l.id === filterState.locationId)}
                        label={t('dashboard:input.location')}
                        keyLabel={'name'}
                        keyEqual={'id'}
                        setFilter={props.handleChangeLocation}
                        isHaveAllOptions={true}
                        allOptionLabel="All locations"
                        isLoading={loading.isLoadingLocation}
                    />
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    <IAutoCompleteRF
                        listData={dataState.companies}
                        itemValue={dataState.companies.find((c) => c.id === filterState.companyId)}
                        label={t('dashboard:input.company')}
                        keyLabel={'displayName'}
                        keyEqual={'id'}
                        setFilter={props.handleChangeCompany}
                        isHaveAllOptions={true}
                        allOptionLabel="All companies"
                        isLoading={loading.isLoadingCompany}
                        disabled={loading.isLoadingCompany || selectField === 'location' || !isGoldRole()}
                    />
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    <IAutoCompleteRF
                        listData={dataState.regions}
                        itemValue={dataState.regions.find((r) => r.id === filterState.regionId)}
                        label={'Region'}
                        keyLabel={'name'}
                        keyEqual={'id'}
                        setFilter={props.handleChangeRegion}
                        isHaveAllOptions={true}
                        allOptionLabel="All regions"
                        isLoading={loading.isLoadingRegion}
                        disabled={loading.isLoadingRegion || !filterState.companyId || selectField === 'location'}
                    />
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    <IAutoCompleteRF
                        listData={dataState.shops}
                        itemValue={dataState.shops.find((t) => t.clientId === filterState.shopId)}
                        label={'Tenant'}
                        keyLabel={'tenantName'}
                        keyEqual={'id'}
                        setFilter={props.handleChangeShop}
                        isHaveAllOptions={true}
                        allOptionLabel="All tenants"
                        isLoading={loading.isLoadingShop}
                        disabled={!dataState.shops.length || loading.isLoadingShop}
                    />
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    <IMultiSelectWithCheckBoxRF
                        limitTags={3}
                        label={t('dashboard:input.zone')}
                        keyId={'id'}
                        keyLabel={'publicName'}
                        listData={dataState.zones}
                        isDisable={!filterState.locationId || (!filterState.shopId && !isBronzeRole())}
                        value={getZones()}
                        setFilter={(zones) => {
                            props.handleChangeZone(zones);
                        }}
                        chipSize={300}
                        placeHolder={filterState.zoneIds?.length ? undefined : t('permits:input.AllZones')}
                    />
                </Grid>
            </Grid>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                <Button
                    variant="cancel"
                    sx={{ padding: '8px 32px', width: '130px' }}
                    onClick={() => handleClose(false)}
                >
                    {t('action:cancel')}
                </Button>
                <Button
                    variant="contained"
                    sx={{ padding: '8px 16px', width: '130px' }}
                    onClick={props.handleApplyFilter}
                >
                    {t('permits:button.applyFilter')}
                </Button>
            </Stack>
        </Grid>
    );
}
