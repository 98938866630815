import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider, PickersDay, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import React, { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CheckedIcon from '../../../../../../assets/images/CheckedIcon';
import UncheckedIcon from '../../../../../../assets/images/UncheckedIcon';
import CalendarIcon from '../../../../../../assets/svg/CalendarIcon';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import useMedia from '../../../../../../hooks/useMedia';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { MAXIMUM_RETREAT_DAY, PermitFromData } from '../../../hooks/useUpsertPermit';
import { RecurProps } from '../../recurringType/DayRecurring';
import { convertHour } from '../../../../../../helpers/ConvertHour';

const useStyles = makeStyles((theme: Theme) => ({
    timeStyle: {
        '& .Mui-disabled': {
            color: 'red !important',
        },
    },
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
    checkBoxc: {
        '.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
    },
}));

const icon = <UncheckedIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;

export default function UpsertPermitItemTimeDuration({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;

    const {
        watch,
        control,
        setValue,
        formState: { isValid, errors },
        setError,
    } = useFormContext<PermitFromData>();

    const { validateFrom, fromHour, toHour, isCheckedAllDay, permitRecurringType, validateTo } = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });

    const { t } = useContext(LanguageContext);
    const classes = useStyles();

    const startTime = moment(fromHour).format('DD/MM/YYYY HH:mm');
    const endTime = moment(toHour).format('DD/MM/YYYY HH:mm');
    const totalMinute = moment(endTime, 'DD/MM/YYYY HH:mm').diff(moment(startTime, 'DD/MM/YYYY HH:mm'), 'minutes');
    const hr = moment.duration(totalMinute, 'minutes').hours();
    const min = moment.duration(totalMinute, 'minutes').minutes();
    const duration = `${hr}hr ${min}min`;
    const startTimeNumber = convertHour.getSecondFromStartOfDay(fromHour || null);
    const endTimeNumber = convertHour.getSecondFromStartOfDay(toHour || null);
    const isNextDay = startTimeNumber && endTimeNumber && startTimeNumber > endTimeNumber;

    const { isMobileSM } = useMedia();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setValue(`permits.${indexForm}.isCheckedAllDay`, isChecked);
        setValue(`permits.${indexForm}.fromHour`, isChecked ? startOfDay(new Date()) : null, { shouldValidate: true });
        setValue(`permits.${indexForm}.toHour`, isChecked ? endOfDay(new Date()) : null, { shouldValidate: true });
    };

    const calculateDuration = () => {
        if (isNextDay) {
            const nextDayEndTime = moment(endTime, 'DD/MM/YYYY HH:mm').add(1, 'days');
            const totalNextDayMinutes = nextDayEndTime.diff(moment(startTime, 'DD/MM/YYYY HH:mm'), 'minutes');
            const nextDayHr = moment.duration(totalNextDayMinutes, 'minutes').hours();
            const nextDayMin = moment.duration(totalNextDayMinutes, 'minutes').minutes();
            return `${nextDayHr}hr ${nextDayMin}min`;
        }
        return duration;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
                mb={
                    Boolean(errors?.permits?.[indexForm]?.toHour) ||
                    Boolean(errors?.permits?.[indexForm]?.validateFrom) ||
                    isNextDay
                        ? 2.5
                        : 1
                }
                xs={12}
                sm={12}
                md={12}
                item
                container
                spacing={2}
                direction="row"
                alignItems={'center'}
                mr={2}
            >
                <Grid lg={4} md={4} sm={4} xs={isCheckedAllDay ? 8.5 : 12} item>
                    <Controller
                        control={control}
                        name={`permits.${indexForm}.validateFrom`}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                label={<LabelWithRequired label={t('permits:text.startDate')} />}
                                value={validateFrom}
                                disabled={isEdit}
                                onChange={(newValue, _) => {
                                    if (!newValue) return;

                                    if (permitRecurringType! === RecurringType.once) {
                                        field.onChange(newValue);
                                        setValue(`permits.${indexForm}.validateTo`, addDays(newValue, 1));
                                    } else {
                                        setValue(`permits.${indexForm}.validateFrom`, newValue, {
                                            shouldValidate: true,
                                        });

                                        if (!isEdit) {
                                            field.onChange(newValue);
                                            setValue(`permits.${indexForm}.validateTo`, null, { shouldValidate: true });
                                        } else {
                                            if (moment(validateTo).diff(moment(newValue)) <= 0) {
                                                setValue(`permits.${indexForm}.validateTo`, null, {
                                                    shouldValidate: true,
                                                });
                                            }
                                        }
                                    }
                                }}
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                OpenPickerButtonProps={{ className: classes.inputStyle }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(error?.message)}
                                        helperText={error?.message}
                                        fullWidth={true}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                renderDay={(day, _value, DayComponentProps) => {
                                    return (
                                        <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                            <PickersDay {...DayComponentProps} />
                                        </Box>
                                    );
                                }}
                                inputFormat="dd/MM/yyyy"
                                minDate={subDays(new Date(), MAXIMUM_RETREAT_DAY)}
                            />
                        )}
                    />
                </Grid>

                <Grid
                    display={isCheckedAllDay ? 'none' : 'flex'}
                    md={Boolean(errors?.permits?.[indexForm]?.fromHour) ? 3 : 2.5}
                    lg={2.5}
                    sm={4}
                    xs={6}
                    mt={{ xs: 1, sm: 0 }}
                    item
                >
                    <Stack
                        spacing={isMobileSM ? 0 : 2}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Typography display={isMobileSM ? 'none' : 'block'} variant="body1">
                            {t('reportPage:text.from')}
                        </Typography>

                        <Controller
                            name={`permits.${indexForm}.fromHour`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    label={<LabelWithRequired label={t('permits:text.startTime')} />}
                                    value={fromHour}
                                    disabled={isEdit}
                                    onChange={(newVal, textVal) => {
                                        if (newVal != null) {
                                            field.onChange(newVal);
                                            setValue(`permits.${indexForm}.toHour`, null, { shouldTouch: true });
                                            setError(`permits.${indexForm}.toHour`, {
                                                type: 'required',
                                                message: t('validation:thisFieldIsRequired'),
                                            });
                                        }
                                    }}
                                    ampm={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(error?.message)}
                                            helperText={error?.message}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    caretColor: 'transparent',
                                                },
                                            }}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Stack>
                </Grid>

                <Grid
                    display={isCheckedAllDay ? 'none' : 'flex'}
                    lg={2.5}
                    md={Boolean(errors?.permits?.[indexForm]?.fromHour) ? 3 : 2.5}
                    sm={4}
                    xs={6}
                    mt={{ xs: 1, sm: 0 }}
                    item
                    position={'relative'}
                >
                    <Stack
                        spacing={isMobileSM ? 0 : 2}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        position={'relative'}
                    >
                        <Typography display={isMobileSM ? 'none' : 'block'} variant="body1">
                            {t('reportPage:text.to')}
                        </Typography>
                        <Controller
                            name={`permits.${indexForm}.toHour`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    ampm={false}
                                    label={
                                        <Stack direction={'row'} gap={1}>
                                            <LabelWithRequired label={t('permits:text.endTime')} />{' '}
                                            {isNextDay && (
                                                <Typography
                                                    sx={{
                                                        color: 'red',
                                                    }}
                                                    component={'div'}
                                                >
                                                    ( +1 )
                                                </Typography>
                                            )}
                                        </Stack>
                                    }
                                    value={toHour}
                                    disabled={isEdit}
                                    onChange={(newVal, textVal) => {
                                        if (newVal != null) {
                                            field.onChange(newVal);
                                        }
                                    }}
                                    OpenPickerButtonProps={{ className: classes.timeStyle }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!error}
                                            // helperText={error?.message}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    caretColor: 'transparent',
                                                },
                                            }}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        {isNextDay && (
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    bottom: -20,
                                    color: 'red',
                                    fontSize: 12,
                                    left: 15,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                Note: Permit extends into next day
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid
                    mt={isMobileSM && !!errors?.permits?.[indexForm]?.toHour ? 1.5 : 0}
                    md={Boolean(errors?.permits?.[indexForm]?.fromHour) ? 1.5 : 3}
                    sm={5}
                    lg={3}
                    xs={isCheckedAllDay ? 3.5 : 12}
                    item
                >
                    <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Typography
                            display={isCheckedAllDay || !Boolean(toHour == null || fromHour) ? 'none' : 'flex'}
                            mr={toHour == null || fromHour == null ? 0 : 2}
                            variant="body1"
                            color={color.priText}
                        >
                            {toHour == null || fromHour == null ? '' : calculateDuration()}
                        </Typography>

                        <Controller
                            control={control}
                            name={`permits.${indexForm}.isCheckedAllDay`}
                            render={({ field, fieldState: { error } }) => (
                                <FormControlLabel
                                    sx={{
                                        '&.MuiFormControlLabel-labelPlacementEnd': {
                                            marginRight: '0px !important',
                                        },
                                    }}
                                    control={
                                        <Checkbox
                                            onChange={handleChange}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={isCheckedAllDay}
                                        />
                                    }
                                    disabled={isEdit}
                                    label={<Typography variant="body1">{t('permits:text.allDay')}</Typography>}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
