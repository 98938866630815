import { Button, Container, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../hooks/useAuth';
import { Role } from '../../models/permission/Role';
import Footer from '../dashboardLayout/components/Footer';
import { BaseDivider, BaseSubHeading } from './components';
import BaseFAQ from './components/BaseFAQ';
import { SectionFAQ, accountManagerSection, agentWithClientSection, tenantSection } from './utils';
import { DrawerContext } from '../dashboardLayout/useDrawer';
type FAQWithAuth = {
    role: Role[];
    components: SectionFAQ[];
};
interface PdfPaths {
    [role: string]: string;
}
export default function FrequentlyAskedQuestions() {
    const { account } = useContext(AuthContext);
    const { setActiveItem } = useContext(DrawerContext);

    const faqWithAuth: FAQWithAuth[] = [
        { role: [Role.Tenant], components: tenantSection },
        {
            role: [Role.AccountManager],
            components: accountManagerSection,
        },
        { role: [Role.ManagingAgent, Role.Client], components: agentWithClientSection },
    ];

    let pdfPaths: PdfPaths = {
        [Role.AccountManager]: 'ihub-ipermit-user-manual-account-manager.pdf',
        [Role.ManagingAgent]: 'ihub-ipermit-user-manual-agent-client.pdf',
        [Role.Client]: 'ihub-ipermit-user-manual-agent-client.pdf',
        [Role.Tenant]: 'ihub-ipermit-user-manual-tenant.pdf',
    };

    useEffect(() => {
        setActiveItem(7);
    }, []);
    return (
        <>
            <>
                <Stack alignItems="center" spacing={1.5}>
                    <Typography variant="h2" fontWeight={500}>
                        Frequently asked questions
                    </Typography>
                    <a href={`/assets/pdf/${pdfPaths[account.label]}`} download>
                        <Button variant="contained">Download the user manual PDF</Button>
                    </a>
                </Stack>
                {faqWithAuth.map(
                    (item, index) =>
                        item.role.includes(account.label) && (
                            <div key={`${index}+${account.label}`}>
                                {item.components.map((item, index) => {
                                    return (
                                        <>
                                            <BaseSubHeading subHeading={item.subHeading} />
                                            {item.content.map((c, i) => {
                                                return (
                                                    <BaseFAQ
                                                        key={i}
                                                        indexOfQuestion={i + 1}
                                                        question={c.question}
                                                        answer={c.answer}
                                                    />
                                                );
                                            })}
                                            <BaseDivider />
                                        </>
                                    );
                                })}
                            </div>
                        )
                )}
            </>
        </>
    );
}
