import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Fade,
    Paper,
    Stack,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useContext, useRef, useState } from 'react';
import { BiCaretDown } from 'react-icons/bi';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { LocationStatus, LocationWithDetail } from '../../models/Location';
import { BoxIAutoComplete, LocationChipStatus } from './IAutoCompleteLocation';

export interface SearchTypeLocation {
    name: SearchTypeEnum;
    id: number;
}

export enum SearchTypeEnum {
    Name = 'Name',
    Postcode = 'Postcode',
    Address = 'Address',
}

type Props<T extends LocationWithDetail> = {
    listData: T[];
    itemValue?: T;
    keyEqual: keyof T;
    keyLabel: keyof T;
    keySearch: keyof T;
    label: string | JSX.Element;
    disabled?: boolean;
    setFilter: (option?: T) => void;
    defaultData?: T;
    key?: any;
    isLoading?: boolean;

    //all options
    isHaveAllOptions?: boolean;
    allOptionLabel?: string;
    open?: boolean;
    subDescLabels?: (keyof T)[];
    keyStatus: keyof T;

    selectType: SearchTypeLocation;
    onChangeType: (type: SearchTypeLocation) => void;
    searchTypes?: SearchTypeLocation[];
    allAddress: (keyof T)[];
};

type PropsStartAdornment = {
    selectType: SearchTypeLocation;
    onChangeType: (type: SearchTypeLocation) => void;
    searchTypes?: SearchTypeLocation[];
    inputRef: React.RefObject<HTMLInputElement>;
};

enum EnumSearchTypeLocation {
    allAddress = 'allAddress',
}

export default function IAutoCompleteSearchTypeLocation<T extends LocationWithDetail>(props: Props<T>) {
    const { t } = useContext(LanguageContext);
    const labelNone = t('permits:input.AllLocations');
    const { keyEqual, keyLabel, keySearch, allAddress, setFilter } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: any, value: T) => {
        if (inputRef.current) {
            inputRef.current.blur();
        }

        if (!value[keyEqual]) {
            return setFilter(undefined);
        }
        return setFilter(value);
    };

    const getAllOptions = () => {
        const allOption: T = {} as T;
        allOption[keyEqual] = undefined as any;
        allOption[keyLabel] = props.allOptionLabel as any;
        return allOption;
    };

    const getOptionLabel = (option: T) => {
        const labelName = option[keyLabel] as unknown as string;
        const keySearchValue = option[keySearch] as unknown as string;
        if (keySearch == keyLabel) {
            return labelName ?? labelNone;
        }
        if (keySearch == EnumSearchTypeLocation.allAddress) {
            const address = allAddress.map((addr) => (option[addr] as unknown as string) ?? '').filter(Boolean);
            return address.length ? `${labelName} (${address.join(', ')})` : labelNone;
        }
        return keySearchValue ? `${labelName} (${keySearchValue})` : labelNone;
    };

    const filterOptions = (options: T[], { inputValue }: { inputValue: string }) => {
        if (keySearch == EnumSearchTypeLocation.allAddress) {
            return options.filter((option) => {
                const combinedAddress = allAddress
                    .map((address) => (option[address] as unknown as string) || '')
                    .filter(Boolean)
                    .join(', ')
                    .toLowerCase();

                return combinedAddress.includes(inputValue.toLowerCase());
            });
        }
        return options.filter((option) => {
            return (option[keySearch] as unknown as string)?.toLowerCase().includes(inputValue.toLowerCase());
        });
    };

    const listData = props.isHaveAllOptions ? [getAllOptions()].concat(props.listData) : props.listData;

    return (
        <BoxIAutoComplete>
            <Autocomplete
                fullWidth
                disablePortal
                noOptionsText={t('dashboard:input.noOptions')}
                open={props.open}
                disabled={props.disabled || props.isLoading}
                defaultValue={props.listData[0]}
                options={listData}
                value={props.itemValue ?? listData[0]}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => option[keyEqual] == value[keyEqual]}
                renderOption={(propss: React.HTMLAttributes<HTMLLIElement>, option, { selected }) => {
                    const isAllItem = option[keyEqual] === undefined;
                    const address = props.allAddress
                        .map((addr) => (option[addr] as unknown as string) ?? '')
                        .filter(Boolean)
                        .join(', ');
                    return (
                        <Box key={Number(option[keyEqual])}>
                            {isAllItem && (
                                <li
                                    {...propss}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        position: 'absolute',
                                        top: 0,
                                        backgroundColor: selected ? color.lightPrimary : color.white,
                                        width: props.listData?.length > 6 ? 'calc(100% - 18px)' : 'calc(100% - 10px)',
                                        padding: '9px 16px',
                                    }}
                                    key={'all'}
                                >
                                    {option[keyLabel] as unknown as string}
                                </li>
                            )}

                            <li
                                {...propss}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    gap: 4,
                                    backgroundColor: selected ? color.lightPrimary : color.white,
                                }}
                            >
                                <Stack flexDirection={'row'} gap={1}>
                                    {option[keyLabel] as unknown as string}
                                    {props.keyStatus &&
                                        LocationChipStatus(
                                            option[props.keyStatus] as unknown as number as LocationStatus
                                        )}
                                </Stack>

                                {props.selectType.name !== SearchTypeEnum.Name && !isAllItem && (
                                    <Typography sx={{ fontSize: '12px', fontStyle: 'italic' }}>
                                        {props.selectType.name === SearchTypeEnum.Address && `(Address: ${address})`}
                                        {props.selectType.name === SearchTypeEnum.Postcode &&
                                            `(Postcode: ${option['postcode']})`}
                                    </Typography>
                                )}
                            </li>
                        </Box>
                    );
                }}
                onChange={(event, value) => {
                    if (value !== null) handleChange(event, value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={inputRef}
                        label={props.label}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: !props.disabled && (
                                <StartAdornment
                                    selectType={props.selectType}
                                    searchTypes={props.searchTypes}
                                    onChangeType={props.onChangeType}
                                    inputRef={inputRef}
                                />
                            ),
                        }}
                    />
                )}
                popupIcon={props.isLoading ? <CircularProgress size={'18px'} /> : <BiCaretDown fontSize={14} />}
                size="small"
            />
        </BoxIAutoComplete>
    );
}

const StartAdornment = ({ selectType: select, onChangeType: onChange, searchTypes, inputRef }: PropsStartAdornment) => {
    const [open, setOpen] = useState(false);
    const isSingleSearch = searchTypes?.length === 1;

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Stack
                paddingLeft={0.5}
                position={'relative'}
                borderRight={`1px solid ${color.grey600}`}
                width={isSingleSearch ? undefined : 105}
                sx={{
                    cursor: isSingleSearch ? 'initial' : 'pointer',
                }}
            >
                <Stack
                    direction={'row'}
                    alignItems="center"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(!open);
                        open ? inputRef?.current?.blur() : inputRef?.current?.focus();
                    }}
                >
                    <Typography mt="-1px" minWidth={75} sx={{ userSelect: 'none' }}>
                        {select.name}
                    </Typography>
                    {!isSingleSearch &&
                        (open ? (
                            <ArrowDropUp style={{ color: color.grey600 }} />
                        ) : (
                            <ArrowDropDown style={{ color: color.grey600 }} />
                        ))}
                </Stack>
                <Fade in={!isSingleSearch && open}>
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '32px',
                            left: '-10px',
                            zIndex: 2000,
                            p: '5px',
                        }}
                    >
                        {searchTypes?.map((type) => {
                            const selected = type.id === select.id;
                            return (
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    key={type.id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(false);
                                        onChange(type);
                                        inputRef?.current?.blur();
                                    }}
                                    borderRadius={1}
                                    padding={1}
                                    bgcolor={selected ? color.lightPrimary : undefined}
                                >
                                    <Typography color={'initial'}>{type.name}</Typography>
                                </Stack>
                            );
                        })}
                    </Paper>
                </Fade>
            </Stack>
        </ClickAwayListener>
    );
};
