import { Checkbox, Chip, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { GridCard } from '../../../components/GridCard';
import color from '../../../config/Colors';
import CheckBoxIcon from '../../../assets/images/CheckBoxIcon';
import CheckedIcon from '../../../assets/images/CheckedIcon';
import IconEdit from '../../companyLayout/employees/components/IconEdit';
import { DangerTooltip } from '../../companyLayout/locationDetail/components/Tab/TableRowTenantsInfo';
import RemoveIcon from '../../../assets/images/RemoveIcon';
import { PermitListItem, ScopeType } from '../../../models/permits/Permit';
import { PermitContext } from '../hooks/usePermits';
import useMedia from '../../../hooks/useMedia';
import { LinkTo } from '../../login/styles';
import { fontInputAddPermits } from '../../../config/Fonts';
import { StatusFilter } from '../../../models/permits/FilterPermits';

type Props = {
    permit: PermitListItem;
};
export default function PermitItem({ permit }: Props) {
    const { t } = useContext(LanguageContext);
    const { handleChangeChecked, checkPermitId, handleOpenConfirmDelete } = useContext(PermitContext);
    const { isMobileSM } = useMedia();

    const getLabel = () => {
        switch (permit.scopeType) {
            case ScopeType.company:
                return t('dashboard:input.company');
            case ScopeType.location:
                return t('dashboard:input.location');
            case ScopeType.zone:
                return t('dashboard:input.zone');
            default:
                break;
        }
    };

    const getStatusColor = (status: StatusFilter) => {
        switch (status) {
            case StatusFilter.Expired:
                return color.danger;
            case StatusFilter.Pending:
                return '#FFB600';
            case StatusFilter.Deleted:
                return color.textPrimary;
            default:
                return color.success;
        }
    };

    const getStatusBackground = (status: StatusFilter) => {
        switch (status) {
            case StatusFilter.Expired:
                return 'rgba(224, 27, 0, 0.1)';
            case StatusFilter.Pending:
                return 'rgba(251, 182, 1, 0.1)';
            case StatusFilter.Deleted:
                return color.grey300;
            default:
                return 'rgba(32, 157, 79, 0.1)';
        }
    };

    const statusLabel =
        permit.status === StatusFilter.Expired
            ? t('action:expired')
            : permit.status === StatusFilter.Pending
            ? t('action:pending')
            : permit.status === StatusFilter.Deleted
            ? 'Deleted'
            : t('action:active');

    const textColor = getStatusColor(permit.status!);
    const backgroundColor = getStatusBackground(permit.status!);

    return (
        <GridCard item container xs={12}>
            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={8} display={'flex'} alignItems="center">
                    {permit.status !== StatusFilter.Deleted && (
                        <Checkbox
                            value={permit.id}
                            sx={{ padding: '0px', mr: '5px' }}
                            size="small"
                            icon={<CheckBoxIcon style={{ width: '22px', height: '22px' }} />}
                            checkedIcon={<CheckedIcon style={{ width: '22px', height: '22px' }} />}
                            onChange={(e) => handleChangeChecked(e)}
                            checked={checkPermitId(Number(permit.id))}
                        />
                    )}

                    <>
                        <LinkTo to={`/permits/edit-permit/${permit.id}`}>
                            <Typography
                                sx={{
                                    '&:hover': {
                                        color: color.primary,
                                        textDecorationLine: 'underline',
                                        transition: '0.3s',
                                        cursor: 'pointer',
                                    },
                                    textTransform: 'uppercase',
                                    fontWeight: 400,
                                    fontFamily: fontInputAddPermits,
                                }}
                                variant="h5"
                                component={'span'}
                            >
                                {permit.vrnNumber}
                            </Typography>
                        </LinkTo>
                    </>

                    <Chip
                        label={
                            <Typography variant="body2" textTransform="capitalize" color={textColor}>
                                {statusLabel}
                            </Typography>
                        }
                        sx={{
                            background: backgroundColor,
                            ml: 2,
                            maxHeight: '28px',
                        }}
                    />
                </Grid>
                {permit.status !== StatusFilter.Deleted && (
                    <Grid
                        item
                        container
                        display={'flex'}
                        justifyContent={'right'}
                        sx={{
                            backgroundColor: `${color.white} !important`,
                        }}
                        xs={4}
                    >
                        <Grid
                            item
                            sx={{
                                backgroundColor: `${color.white} !important`,
                            }}
                        >
                            <LinkTo to={`/permits/edit-permit/${permit.id}`}>
                                <Tooltip title={t('action:edit')} placement="top">
                                    <IconButton
                                        aria-label="fingerprint"
                                        color="success"
                                        sx={{
                                            backgroundColor: color.grey200,
                                            '&:hover': {
                                                backgroundColor: color.grey200,
                                            },
                                            padding: 0,
                                            border: `5px solid ${color.grey100}`,
                                            width: '32px',
                                            height: '32px',
                                        }}
                                    >
                                        <IconEdit width={'26px'} height={'26px'} />
                                    </IconButton>
                                </Tooltip>
                            </LinkTo>
                        </Grid>
                        <Grid item>
                            <DangerTooltip title={t('action:delete')} placement="top">
                                <IconButton
                                    onClick={() => handleOpenConfirmDelete(permit.id)}
                                    aria-label="fingerprint"
                                    color="success"
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '& .svg-icon-stroke': {
                                            stroke: color.danger,
                                        },
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                        },
                                        border: `5px solid ${color.grey100}`,
                                        padding: 0,
                                        width: '32px',
                                        height: '32px',
                                    }}
                                >
                                    <RemoveIcon width={'26px'} height={'26px'} />
                                </IconButton>
                            </DangerTooltip>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid
                pt={1}
                item
                container
                direction={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={1}
                // sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
                <Typography variant="body1">
                    {getLabel()}: {permit.scopeName}
                </Typography>

                <Stack
                    direction={isMobileSM ? 'column' : 'row'}
                    justifyContent={'flex-start'}
                    alignItems={isMobileSM ? 'start' : 'center'}
                    spacing={1}
                >
                    <Typography variant="body1">
                        {t('permits:text.permitCategory')}: {permit.type} <span style={{ textTransform: 'capitalize' }}></span>
                    </Typography>
                </Stack>

                <Typography variant="body1">
                    {t('permits:text.tenant')}: {permit.tenantName ?? 'N/A'}
                </Typography>
            </Grid>
        </GridCard>
    );
}
